/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { FormattedMessage,useIntl  } from 'react-intl'
import { Link } from 'react-router-dom'

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper l3a3ta9q51-editor_css' },
  page: { className: 'home-page l3a3u4xwzw7-editor_css' },
  logo: {
    className: 'header2-logo l3a3ua3ic0j-editor_css',
    children: 'https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '当前页面 ID 地址，参考如上',
        children: '导航名称',
        className: 'menu-item l3a3tmh4g8e-editor_css',
      },
      {
        name: 'linkNav~l3a3wvhtxg9',
        to: '当前页面 ID 地址，参考如上',
        children: '导航名称',
        className: 'menu-item l3a3tmh4g8e-editor_css',
      },
      {
        name: 'linkNav~l3a3wvx5tsm',
        to: '当前页面 ID 地址，参考如上',
        children: '导航名称',
        className: 'menu-item l3a3tmh4g8e-editor_css',
      },
      {
        name: 'linkNav~l3a3wwcpp9d',
        to: '当前页面 ID 地址，参考如上',
        children: '导航名称',
        className: 'menu-item l3a3tmh4g8e-editor_css',
      },
      {
        name: 'linkNav~l3a3wwvlq7j',
        to: '当前页面 ID 地址，参考如上',
        children: '导航名称',
        className: 'menu-item l3a3tmh4g8e-editor_css',
      },
      {
        name: 'linkNav~l3a3wxbdlhb',
        to: '当前页面 ID 地址，参考如上',
        children: '导航名称',
        className: 'menu-item l3a3tmh4g8e-editor_css',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
}
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'home',
        className: 'header3-item',
        children: <FormattedMessage id="Nav30DataSource.Home" />,
      },
      {
        name: 'philosophy',
        className: 'header3-item',
        children: <FormattedMessage id="Nav30DataSource.Philosophy" />,
      },
      // {
      //   name: 'team',
      //   className: 'header3-item',
      //   children: <FormattedMessage id="Nav30DataSource.Team" />,
      // },
      {
        name: 'knowledgebank',
        className: 'header3-item',
        children: <FormattedMessage id="Nav30DataSource.KnowlegeBank" />,
      },
      {
        name: 'careers',
        className: 'header3-item',
        children: <FormattedMessage id="Nav30DataSource.Careers" />,
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
}
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 l3a2wojaw29-editor_css' },
  page: { className: 'home-page banner5-page l3a33ekuwj-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              <span style={{ zIndex: 999, position: 'relative' }}>
                <FormattedMessage id="Banner50DataSource.title1" />
              </span>

              <br className="banner5-content-br" />
              <span className="banner5-content-span">
                {/* <svg className="banner5-content-icon" width="387" height="67" viewBox="0 0 387 67">
                   <path className="path"  fill="none" stroke="#E04E51" stroke-width="1" d="M147.436 5.21862C98.5472 5.40879 52.507 13.9482 23.5828 23.833C0.0685169 31.8869 -11.9322 43.8663 17.213 53.08C42.3484 61.035 85.3021 63.8369 120.214 65.4024C155.243 66.9814 190.428 66.9272 225.028 65.9976C283.533 64.4216 391.525 59.1268 386.569 37.5569C383.756 25.2024 345.806 12.7095 307.75 6.51376C261.85 -0.956812 208.173 -0.482462 160.218 2.27145C111.556 5.07311 65.4857 10.9734 35.6891 21.9143C11.5889 30.7619 -6.83918 45.3878 24.7996 55.6517C52.7772 64.7299 104.544 65.7284 142.227 65.0016C187.901 64.1209 233.373 62.5461 276.596 58.8932C316.244 55.5318 358.112 49.9619 377.091 39.3552C390.601 31.8271 388.499 23.3988 362.92 17.3608C336.722 11.1681 298.902 9.0379 266.761 7.6355C231.456 6.10376 196.988 5.89502 162.189 6.88448C153.88 7.12773 145.533 7.35929 137.226 7.58103C133.504 7.67527 135.032 9.26247 138.716 9.1673C161.913 8.55785 184.851 7.64069 208.363 7.80335C224.872 7.92811 241.448 8.30214 257.973 8.90112C292.274 10.1397 331.62 12.2848 359.961 18.6794C394.032 26.363 378.146 38.562 354.221 45.3892C321.527 54.721 269.851 58.3661 223.99 60.7176C180.482 62.9423 131.937 64.9499 86.8843 62.6686C65.0605 61.5662 42.562 59.0263 27.4943 54.133C10.1147 48.4846 12.1605 40.8983 18.8375 34.6717C35.2612 19.4057 83.8449 10.0547 136.93 5.59249C188.011 1.30861 247.689 -0.271703 299.351 7.0133C325.085 10.6427 345.702 16.5084 361.075 23.2074C373.303 28.5498 382.6 34.6299 380.622 40.8071C377.221 51.3549 337.237 56.8404 305.432 59.8505C268.242 63.3782 227.398 64.7404 187.466 65.0258C150.197 65.2779 111.626 64.1313 75.2323 60.8183C54.9315 58.9661 34.5187 56.1852 20.0235 51.5964C3.7934 46.46 2.86731 39.6427 9.91668 33.8127C19.3301 26.0145 42.3618 19.8659 65.7129 15.2218C88.7878 10.6249 117.611 6.94062 147.398 6.82229C151.078 6.81317 151.194 5.20061 147.436 5.21862Z"/>
               </svg> */}

                <svg
                  id="svgicon"
                  className="banner5-content-icon"
                  width="387"
                  height="67"
                  viewBox="0 0 387 67"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="path"
                    fill="none"
                    stroke="#E04E51"
                    stroke-width="2"
                    d="M147.436 5.21862C98.5472 5.40879 52.507 13.9482 23.5828 23.833C0.0685169 31.8869 -11.9322 43.8663 17.213 53.08C42.3484 61.035 85.3021 63.8369 120.214 65.4024C155.243 66.9814 190.428 66.9272 225.028 65.9976C283.533 64.4216 391.525 59.1268 386.569 37.5569C383.756 25.2024 345.806 12.7095 307.75 6.51376C261.85 -0.956812 208.173 -0.482462 160.218 2.27145C111.556 5.07311 65.4857 10.9734 35.6891 21.9143C11.5889 30.7619 -6.83918 45.3878 24.7996 55.6517C52.7772 64.7299 104.544 65.7284 142.227 65.0016C187.901 64.1209 233.373 62.5461 276.596 58.8932C316.244 55.5318 358.112 49.9619 377.091 39.3552C390.601 31.8271 388.499 23.3988 362.92 17.3608C336.722 11.1681 298.902 9.0379 266.761 7.6355C231.456 6.10376 196.988 5.89502 162.189 6.88448C153.88 7.12773 145.533 7.35929 137.226 7.58103C133.504 7.67527 135.032 9.26247 138.716 9.1673C161.913 8.55785 184.851 7.64069 208.363 7.80335C224.872 7.92811 241.448 8.30214 257.973 8.90112C292.274 10.1397 331.62 12.2848 359.961 18.6794C394.032 26.363 378.146 38.562 354.221 45.3892C321.527 54.721 269.851 58.3661 223.99 60.7176C180.482 62.9423 131.937 64.9499 86.8843 62.6686C65.0605 61.5662 42.562 59.0263 27.4943 54.133C10.1147 48.4846 12.1605 40.8983 18.8375 34.6717C35.2612 19.4057 83.8449 10.0547 136.93 5.59249C188.011 1.30861 247.689 -0.271703 299.351 7.0133C325.085 10.6427 345.702 16.5084 361.075 23.2074C373.303 28.5498 382.6 34.6299 380.622 40.8071C377.221 51.3549 337.237 56.8404 305.432 59.8505C268.242 63.3782 227.398 64.7404 187.466 65.0258C150.197 65.2779 111.626 64.1313 75.2323 60.8183C54.9315 58.9661 34.5187 56.1852 20.0235 51.5964C3.7934 46.46 2.86731 39.6427 9.91668 33.8127C19.3301 26.0145 42.3618 19.8659 65.7129 15.2218C88.7878 10.6249 117.611 6.94062 147.398 6.82229C151.078 6.81317 151.194 5.20061 147.436 5.21862Z"
                  />
                </svg>
                <span className="banner5-content-text">
                  <FormattedMessage id="Banner50DataSource.title2" />
                </span>
              </span>
              <span className="banner5-content-text">
                <FormattedMessage id="Banner50DataSource.title3" />
              </span>
            </p>
          </span>
        ),
        className: 'banner5-title l3a31uq47eq-editor_css',
      },
      {
        name: 'content~l3a32x9n9nq',
        className: 'l3a32t68bt-editor_css',
        children: (
          <span>
            <p>
              <FormattedMessage id="Banner50DataSource.content" />
            </p>
          </span>
        ),
      },
      // {
      //   name: 'icon',
      //   children: (
      //  <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/icon.svg" />
      //   ),
      //   className: 'banner5-content-icon',
      // },
    ],
  },
  image: {
    className: 'banner5-image l3a30yde5-editor_css',
    children:
      'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/heavenly.png',
  },
}
export const Feature70DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature7-wrapper l3a19qads6q-editor_css',
  },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1 l3a19wqwtz9-editor_css',
        children: (
          <span>
            <p>
              <FormattedMessage id="Feature70DataSource.title" />
            </p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content l3a1ads3x8-editor_css',
        children: (
          <span>
            <p>
              <FormattedMessage id="Feature70DataSource.content" />
            </p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 56,
    children: [
      {
        md: 12,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group l3a1b2kid26-editor_css',
          children: [
            {
              name: 'content',
              className: 'feature7-block-content l3a1at126t8-editor_css',
              children: (
                <span>
                  <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/platform1.png" />
                  <p>
                    <FormattedMessage id="Feature70DataSource.block1" />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block~l3a2fan75yu',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group l3a1b2kid26-editor_css',
          children: [
            {
              name: 'content',
              className: 'feature7-block-content l3a1at126t8-editor_css',
              children: (
                <span>
                  <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/platform2.png" />
                  <p>
                    <FormattedMessage id="Feature70DataSource.block2" />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block~l3a2fb6jda',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group l3a1b2kid26-editor_css',
          children: [
            {
              name: 'content',
              className: 'feature7-block-content l3a1at126t8-editor_css',
              children: (
                <span>
                  <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/platform3.png" />
                  <p>
                    <FormattedMessage id="Feature70DataSource.block31" />
                  </p>
                  <p>
                    <FormattedMessage id="Feature70DataSource.block32" />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block~l3a2fc1o4on',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group l3a1b2kid26-editor_css',
          children: [
            {
              name: 'content',
              className: 'feature7-block-content l3a1at126t8-editor_css',
              children: (
                <span>
                  <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/platform4.png" />
                  <p>
                    <FormattedMessage id="Feature70DataSource.block4" />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper l3a2gn3d8to-editor_css',
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <p className="title-wrapper-text">
            <span>
              <FormattedMessage id="Content00DataSource.title" />
            </span>
            <img
              className="content0-text-icon"
              src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg1.png"
            />
          </p>
        ),
      },
      {
        name: 'content~l3a2fx575mt',
        className: 'content0-text',
        children: <FormattedMessage id="Content00DataSource.content" />,
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon1',
              children: (
                <img
                  className="content0-block-icon1-img1"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/market1.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <FormattedMessage id="Content00DataSource.block1.title" />
              ),
            },
            {
              name: 'content',
              className: 'content0-block-desc',
              children: (
                <FormattedMessage id="Content00DataSource.block1.content" />
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon1',
              children: (
                <img
                  className="content0-block-icon1-img2"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/market2.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <FormattedMessage id="Content00DataSource.block2.title" />
              ),
            },
            {
              name: 'content',
              className: 'content0-block-desc',
              children: (
                <FormattedMessage id="Content00DataSource.block2.content" />
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon1',
              children: (
                <img
                  className="content0-block-icon1-img3"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/market3.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <FormattedMessage id="Content00DataSource.block3.title" />
              ),
            },
            {
              name: 'content',
              className: 'content0-block-desc',
              children: (
                <FormattedMessage id="Content00DataSource.block3.content" />
              ),
            },
          ],
        },
      },
    ],
  },
}
const FunctionComponent = () => {
  const intl = useIntl()
  return ( <img
    style={{width:'100%'}}
      src={`${window.location.origin}/home/${ intl.formatMessage({ id : "Content001DataSource.img"})}`}
    />)
}

export const Content001DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper l3a2gn3d8t1-editor_css',
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <p className="title-wrapper-text">
            <span style={{color: 'white'}}>
              <FormattedMessage id="Content001DataSource.title" />
            </span>
            <img
              className="content0-text-icon"
              src={"https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg1.png"}
            />
          </p>
        ),
      }
    ],
  },
  childWrapper: {
    children: [
      {
        md: 24,
        xs: 24,
        children: {
          children: [
            {
              name: 'image',
              children: (
               <FunctionComponent/>
              ),
            },
          ],
        },
      }
    ],
  },
}

export const Feature00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper content0-wrapper-color ',
  },
  page: { className: 'home-page content0 content0-nopadding bgNone' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <p className="title-wrapper-text-right">
            <span>
              <FormattedMessage id="Feature00DataSource.title" />
            </span>
            <img
              className="content0-text-icon1"
              src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg2.png"
            />
          </p>
        ),
        className: 'l3a2i56xfnu-editor_css',
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block content0-block-div',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon2 l3a2kt4hyx-editor_css',
              children: (
                <img
                  className="content0-block-icon2-img1"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/data1.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title l3a2j5955u-editor_css',
              children: (
                <span>
                  <p>$1B</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'l3a2kt4hyx-editor_css_content',
              children: (
                <FormattedMessage id="Feature00DataSource.block1.title" />
              ),
            },
          ],
        },
      },
      {
        name: 'block~l3a2llqbi9i',
        className: 'content0-block content0-block-div',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon2 l3a2kt4hyx-editor_css',
              children: (
                <img
                  className="content0-block-icon2-img1"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/data2.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title l3a2j5955u-editor_css',
              children: (
                <span>
                  <p>20+</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'l3a2kt4hyx-editor_css_content',
              children: (
                <FormattedMessage id="Feature00DataSource.block2.title" />
              ),
            },
          ],
        },
      },
      {
        name: 'block~l3a2lmb81or',
        className: 'content0-block content0-block-div',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon2 l3a2kt4hyx-editor_css',
              children: (
                <img
                  className="content0-block-icon2-img1"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/data3.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title l3a2j5955u-editor_css',
              children: (
                <span>
                  <p>20+</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'l3a2kt4hyx-editor_css_content',
              children: (
                <FormattedMessage id="Feature00DataSource.block3.title" />
              ),
            },
          ],
        },
      },
      {
        name: 'block~l3a2lmtn90b',
        className: 'content0-block content0-block-div',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon2 l3a2kt4hyx-editor_css',
              children: (
                <img
                  className="content0-block-icon2-img1"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/data4.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title l3a2j5955u-editor_css',
              children: (
                <span>
                  <p>100+</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'l3a2kt4hyx-editor_css_content',
              children: (
                <FormattedMessage id="Feature00DataSource.block4.title" />
              ),
            },
          ],
        },
      },
      {
        name: 'block~l3a2lnerzi',
        className: 'content0-block content0-block-div',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon2 l3a2kt4hyx-editor_css',
              children: (
                <img
                  className="content0-block-icon2-img1"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/data5.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title l3a2j5955u-editor_css',
              children: (
                <span>
                  <p>200+</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'l3a2kt4hyx-editor_css_content',
              children: (
                <FormattedMessage id="Feature00DataSource.block5.title" />
              ),
            },
          ],
        },
      },
      {
        name: 'block~l3a2lnzv3di',
        className: 'content0-block content0-block-div',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon2 l3a2kt4hyx-editor_css',
              children: (
                <img
                  className="content0-block-icon2-img1"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/data6.png"
                />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title l3a2j5955u-editor_css',
              children: (
                <span>
                  <p>80+</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'l3a2kt4hyx-editor_css_content',
              children: (
                <FormattedMessage id="Feature00DataSource.block6.title" />
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Teams00DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams0-wrapper l3a40ov85a6-editor_css',
  },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'title',
              children: (
                <span>
                  <p>
                    <FormattedMessage id="Teams00DataSource.title" />
                  </p>
                </span>
              ),
              className: 'teams0-h1 l3a40nbbtr-editor_css',
            },
            {
              name: 'content2',
              children: (
                <>
                  <span>
                    <p>
                      <FormattedMessage id="Teams00DataSource.content" />
                    </p>
                  </span>
                </>
              ),
              className: 'teams0-content l3a40zlrxyl-editor_css',
            },
          ],
        },
      },
    ],
  },
}
export const Content50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper l3a2r4x6ks-editor_css',
  },
  page: { className: 'home-page content5 l3a2ryo598-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              <img
                className="l3a2r4x6ks-editor_css_icon"
                src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/icon1.svg"
                alt=""
              />
              <FormattedMessage id="Content50DataSource.title" />
            </p>
          </span>
        ),
        className: 'title-h1 l3a44ohw8bn-editor_css',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/future1.png',
            className: 'l3a4788p8q-editor_css',
          },
          content: {
            children: (
              <FormattedMessage id="Content50DataSource.block1.title" />
            ),
            className: 'l3a2rjapme-editor_css',
          },
        },
      },
      {
        name: 'block~l3a47r5keth',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/future2.png',
            className: 'l3a4788p8q-editor_css',
          },
          content: {
            children: (
              <FormattedMessage id="Content50DataSource.block2.title" />
            ),
            className: 'l3a2rjapme-editor_css',
          },
        },
      },
      {
        name: 'block~l3a47rlc37',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/future3.png',
            className: 'l3a4788p8q-editor_css',
          },
          content: {
            children: (
              <FormattedMessage id="Content50DataSource.block3.title" />
            ),
            className: 'l3a2rjapme-editor_css',
          },
        },
      },
      {
        name: 'block~l3a47s6w35',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/future4.png',
            className: 'l3a4788p8q-editor_css',
          },
          content: {
            children: (
              <FormattedMessage id="Content50DataSource.block4.title" />
            ),
            className: 'l3a2rjapme-editor_css',
          },
        },
      },
      {
        name: 'block~l3a47t088z',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/future5.png',
            className: 'l3a4788p8q-editor_css',
          },
          content: {
            children: (
              <FormattedMessage id="Content50DataSource.block5.title" />
            ),
            className: 'l3a2rjapme-editor_css',
          },
        },
      },
      {
        name: 'block~l3a47tjk87u',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/future6.png',
            className: 'l3a4788p8q-editor_css',
          },
          content: {
            children: (
              <FormattedMessage id="Content50DataSource.block6.title" />
            ),
            className: 'l3a2rjapme-editor_css',
          },
        },
      },
    ],
  },
}
export const Content120DataSource = {
  wrapper: {
    className: 'home-page-wrapper content12-wrapper l3a48v6nzyf-editor_css',
  },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '', className: 'title-h1' }],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        children: {
          wrapper: { className: 'block-content block-content-img0' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo1.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo1_active.png',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        children: {
          wrapper: { className: 'block-content block-content-img1 ' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo2.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo2_active.png',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        children: {
          wrapper: { className: 'block-content block-content-img2' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo3.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo3_active.png',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        children: {
          wrapper: { className: 'block-content block-content-img3' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo4.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo4_active.png',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',

        children: {
          wrapper: { className: 'block-content  block-content-img4' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo5.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo5_active.png',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',

        children: {
          wrapper: { className: 'block-content  block-content-img5' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo6.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo6_active.png',
          },
        },
      },
      {
        name: 'block6',
        className: 'block',

        children: {
          wrapper: { className: 'block-content  block-content-img6' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo7.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo7_active.png',
          },
        },
      },
      {
        name: 'block7',
        className: 'block',

        children: {
          wrapper: { className: 'block-content  block-content-img7' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo8.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo8_active.png',
          },
        },
      },
      {
        name: 'block8',
        className: 'block',

        children: {
          wrapper: { className: 'block-content  block-content-img8' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo9.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo9_active.png',
          },
        },
      },
      {
        name: 'block9',
        className: 'block',

        children: {
          wrapper: { className: 'block-content  block-content-img9' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo10.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo10_active.png',
          },
        },
      },
      {
        name: 'block10',
        className: 'block',

        children: {
          wrapper: { className: 'block-content  block-content-img10' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo11.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo11_active.png',
          },
        },
      },
      {
        name: 'block11',
        className: 'block',

        children: {
          wrapper: { className: 'block-content  block-content-img11' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo12.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo12_active.png',
          },
        },
      },
      {
        name: 'block12',
        className: 'block',

        children: {
          wrapper: { className: 'block-content block-content-img12' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo13.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo13_active.png',
          },
        },
      },
      {
        name: 'block13',
        className: 'block',

        children: {
          wrapper: { className: 'block-content block-content-img13' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo14.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo14_active.png',
          },
        },
      },
      {
        name: 'block14',
        className: 'block',

        children: {
          wrapper: { className: 'block-content block-content-img14' },
          img: {
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo15.png',
            active:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/logo15_active.png',
          },
        },
      },
    ],
  },
}
export const Footer11DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer1-wrapper l3a4fjofzrg-editor_css',
  },
  OverPack: { className: 'footer1 l3a4dh6vxnu-editor_css', playScale: 0.2 },
  block: {
    className: 'home-page l3a4e5y5if-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 12,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <div className="logo_div">
              {/* <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/slogan1.svg" />
            <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/slogan2.svg" />
            <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/slogan3.svg" /> */}
            </div>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  <div className="slogan-div">
                  <Link
                      reloadDocument={true}
                      className="slogan-span"
                      to={'philosophy'}
                    >
                      <FormattedMessage id="Nav30DataSource.Philosophy" />
                    </Link>
                    <Link
                      reloadDocument={true}
                      className="slogan-span"
                      to={'philosophy'}
                    >
                      <FormattedMessage id="Nav30DataSource.Philosophy" />
                    </Link>
                    {/* <Link
                      reloadDocument={true}
                      className="slogan-span"
                      to={'philosophy'}
                    >
                      <FormattedMessage id="Nav30DataSource.Philosophy" />
                    </Link>
                    <Link
                      reloadDocument={true}
                      className="slogan-span"
                      to={'team'}
                    >
                      <FormattedMessage id="Nav30DataSource.Team" />
                    </Link> */}
                    <Link
                      reloadDocument={true}
                      className="slogan-span"
                      to={'knowledgebank'}
                    >
                      <FormattedMessage id="Nav30DataSource.KnowlegeBank" />
                    </Link>
                    <a
                      className="slogan-span"
                      href="https://shixiangtech.jobs.feishu.cn/index"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage id="Nav30DataSource.Careers" />
                    </a>
                  </div>
                  <p style={{ margin: '0' }} className="l3a4dda9nwb-editor_css">
                    <a
                      className="slogan-span"
                      href="https://beian.miit.gov.cn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      沪ICP备20018674号-1
                      <br/>
                      @2022 shixiang. All Rights Reserved
                    </a>
                  </p>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block l3a4eq60zw-editor_css',
        title: {
          children: (
            <img src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/flogo.png" />
          ),
          className: 'l3a4fca48nc-editor_css',
        },
        childWrapper: {
          children: [],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright l3a4dda9nwb-editor_css',
    children: '',
  },
}
