import React from 'react'
import { Tag, Avatar } from 'antd'
import { FormattedMessage } from 'react-intl'

const json = [
   {
    title: 'Deep Dive on Hugging Face',
    date: 'Nov 14, 2022',
    author: 'Everything Unicorn',
    authorAvatar:`${window.location.origin}/knowledgeimg/a1.jpg`,
    link: 'https://everythingunicorn.substack.com/p/deep-dive-on-hugging-face',
    desc: 'The highway to AI/ML gold rush',
    img: `${window.location.origin}/knowledgeimg/img1.JPEG`
  },
  {
    title: 'ICONIQ Growth: Beyond the family office, Invisible King in SaaS',
    date: 'Nov 28, 2022',
    author: 'Everything Unicorn',
    authorAvatar:`${window.location.origin}/knowledgeimg/a1.jpg`,
    link: 'https://everythingunicorn.substack.com/p/iconiq-growth-beyond-the-family-office',
    desc: 'ICONIQ Growth seized the golden decade for growth investment in software companies.',
    img: `${window.location.origin}/knowledgeimg/img2.jpg`
  },
  {
    title: 'dbt: A New "Species" in Data Industry',
    date: 'Dec 19, 2022',
    author: 'Everything Unicorn',
    authorAvatar:`${window.location.origin}/knowledgeimg/a1.jpg`,
    link: 'https://everythingunicorn.substack.com/p/dbt-a-new-species-in-data-industry',
    desc: 'Deep dive on this $4.2 billion data transformation tool',
    img: `${window.location.origin}/knowledgeimg/img3.png`
  },
  {
    title: 'Belvo: Well Beyond Plaid for Latam',
    date: 'Jan 17, 2023',
    author: 'Everything Unicorn',
    authorAvatar:`${window.location.origin}/knowledgeimg/a1.jpg`,
    link: 'https://everythingunicorn.substack.com/p/belvo-well-beyond-plaid-for-latam',
    desc: 'A talk with Ray, VP Product at Belvo',
    img: `${window.location.origin}/knowledgeimg/img4.png`
  },
  // {
  //   title: '估值34亿美元，MoonPay是PayPal for Web3吗?',
  //   date: '2022/6/7',
  //   author: '程天一',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/vFw9uzCWmDuW-cv7vm_yzw',
  //   desc: 'MoonPay 是目前 Crypto 世界最知名和规模最大的法币出入金品牌。对 MoonPay 常见的称呼是 PayPal for Crypto，但是 MoonPay 拥有着更酷且更年轻的品牌形象。',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/1.png'
  // },
  // {
  //   title: 'Neuralink：半神世界的启蒙者 ',
  //   date: '2022/6/7',
  //   author: 'Silicon、Ting',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/FxI-gIv-OAv-hQMFXyMjHg',
  //   desc: 'Elon Musk 对 Neuralink 的终极设想源于他对超级人工智能可能带来的人类生存危机，他希望人类能够与 AI “共生”，将 AI 真正变为人类自己所有。Neuralink 完全有能力重新定义未来人类将是什么。',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/2.png'
  // },
  // {
  //   title: '为什么我们认为 Global SaaS 值得投资？',
  //   date: '2022/6/7',
  //   author: '海外独角兽团队',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/tJzDHv3lH9G92D2ynKjKDw',
  //   desc: 'SaaS 是拾象持续深入研究的赛道，我们一直高度看好 Global SaaS 的相关投资机会。SaaS 的发展趋势是不可逆的，优秀的 SaaS 是企业级消费品，能够持续为全球投资者贡献复利。',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/3.png'
  // },
  // {
  //   title: '数字资产托管：诞生多个独角兽，Crypto与传统金融的交汇处 ',
  //   date: '2022/6/7',
  //   author: '海外独角兽团队',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/Uyg2t0w9jsg56h3VLbVY0A',
  //   desc: '2021 年，加密货币领域的投资中有 17% 流向了加密货币托管与经纪服务商，数字资产管理市场和托管解决方案互为依托、快速发展，这个领域很有可能诞生多家百亿美元级别的公司。',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/4.PNG'
  // },
  // {
  //   title: 'Discord 才是真正通往元宇宙的大门',
  //   date: '2022/6/7',
  //   author: 'Siqi、Lavida',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/6PJWGWLOZLh5ZPUyS21pHg',
  //   desc: '和 Meta 相比，Discord 更像真正的“元宇宙原生”：这款最初只是为游戏玩家们设计的产品，早已扩大了影响边界，成为包括教育、投资、Crypto 等各个领域社群成员们的聚集地，同时它也成为了很多 Web3 组织的首选。',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/5.png'
  // },
  // {
  //   title: 'MetaMask：当之无愧的加密货币英雄',
  //   date: '2022/6/7',
  //   author: 'Mario Gabriele',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/m43cg7Qk9XUeFLsJCellCw',
  //   desc: 'MetaMask 的历史可以追溯到有史以来第一次以太坊开发者大会。作为加密领域使用最为广泛的钱包产品，标志性的“小狐狸” MetaMask 将数千万用户带领到了 web3 世界之中。',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/6.PNG'
  // }
  // ,
  // {
  //   title: 'AngelList：当之无愧的去中心化投资范式引领者',
  //   date: '2022/6/7',
  //   author: 'Mario Gabriele',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/SnY6DaAn3sdM2yWgR5zAyA',
  //   desc: '从创立以来，拾象一直在探索创新的投资范式，并在去中心化投资、更灵活的募资、数字化的 workflow、开放信息共享等方面有了一些进展。我们希望推动信息和认知平权，赋予个体自主投资的机会和能力。这和 AngelList 展现出的理念高度一致。',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/7.png'
  // }
  // ,
  // {
  //   title: 'SpaceX的梦想与征途：Starlink星链计划',
  //   date: '2022/6/7',
  //   author: 'junyu',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/NLNYdzj-O5JTP7pWvcSqag',
  //   desc: 'SpaceX 是全球最激动人心的创新公司。火箭发射业务为 Starlink 提供动力，让卫星网络通讯能为全球电信带来新的、巨大的自由现金流。而 Starlink 会反哺 SpaceX 继续投资其核心技术能力，并建造星际飞船',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/8.png'
  // }
  // ,
  // {
  //   title: 'Epic Games 千亿美金之路（上篇）：虚幻引擎',
  //   date: '2022/6/7',
  //   author: 'Matthew Ball & Jacob Navok',
  //   authorAvatar:
  //     'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/wx1.png',
  //   link: 'https://mp.weixin.qq.com/s/2EPKv1NKIcLj5OwEFvqbmA',
  //   desc: 'Epic Games 是全球游戏行业中唯一一家既打造了全球爆款游戏，也研发了顶级游戏引擎，并运营了过亿用户的游戏商店的公司。Epic Games 有能力在未来重新塑造全球游戏生态，成为社会数字化未来的中心',
  //   img: 'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/knowledgebank/9.PNG'
  // }
]

const getBlockChildren = (children) =>{
  const arr = json.filter((i,j)=>j!==0).map((i,j)=>{
    return  {
      name: `block${j}`,
      className: 'block-top',
      md: 12,
      xs: 24,
      titleWrapper: {
        children: [
          {
            name: 'image',
            className: 'teams3-top-image l3mufki7ho-editor_css',
            children: (
              <img
                src={i.img}
                alt=""
              />
            )
          },
          {
            name: 'content',
            className: 'teams3-job',
            children: (
              <div className="teams3-job-div">
                <p className="teams3-job-tip">{i.date}</p>
                {/* <Tag>Newsletter</Tag> */}
              </div>
            )
          },
          {
            name: 'title',
            className: 'teams3-top-title',
            children: (
              <a
                href={i.link}
                target="_blank"
                rel="noreferrer"
              >
               {i.title}
              </a>
            )
          },
          {
            name: 'content',
            className: 'teams3-top-job',
            children: i.desc
          },
          {
            name: 'content1',
            className: 'teams3-top-content',
            children: (
              <p>
                <Avatar
                  // size={48}
                  className="teams3-top-content-avatar"
                  src={i.authorAvatar}
                />
                <span className="teams3-top-content-text" style={{ marginLeft: '14px' }}>{i.author}</span>
              </p>
            )
          }
        ]
      }
    }
  })
  return arr
}

export const Teams30DataSource = {
  wrapper: { className: 'home-page-wrapper teams3-wrapper' },
  page: { className: 'home-page teams3' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: <FormattedMessage id="Teams30DataSource.title" />,
        className: 'l3mujrvlabs-editor_css'
      },
      {
        name: 'content~l3mujv3knoq',
        className: 'l3mujyr5gy-editor_css',
        children: <FormattedMessage id="Teams30DataSource.content" />
      }
    ]
  },
  block: {
    className: 'block-wrapper',
    gutter: 72,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 24,
        xs: 24,
        image: {
          md: 24,
          xs: 24,
          name: 'image',
          className: 'teams3-image l3muhop3pkf-editor_css',
          children:json[0].img
        },
        titleWrapper: {
          className: 'teams3-textWrapper',
          md: 24,
          xs: 24,
          children: [
            {
              name: 'content',
              className: 'teams3-job',
              children: (
                <div>
                  <p className="teams3-job-tip">{json[0].date}</p>
                  {/* <Tag>Web3</Tag> */}
                </div>
              )
            },
            {
              name: 'title',
              className: 'teams3-title l3mujk43ofb-editor_css',
              children: (
                <a
                  href={json[0].link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {json[0].title}
                </a>
              )
            },
            {
              name: 'content',
              className: 'teams3-job teams3-job-span ',
              children:json[0].desc
            },
            {
              name: 'content1',
              className: 'teams3-content l3muitic92j-editor_css',
              children: (
                <p>
                  <Avatar
                    className="teams3-top-content-avatar"
                    src={json[0].authorAvatar}
                  />
                  <span className="teams3-top-content-text" style={{ marginLeft: '14px' }}>{json[0].author}</span>
                </p>
              )
            }
          ]
        }
      }
    ]
  },
  blockBottom: {
    className: 'block-top-wrapper',
    gutter: 104,
    children: [
      ...getBlockChildren()
    ]
  }
}
