import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate  } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav3';
import Footer from './Home/Footer1';
import Home from './Home';
import Philosophy from './Philosophy';
import Team from './Team';
import KnowledgeBank from './KnowledgeBank';

import enUS from 'antd/lib/locale/en_US'
import zhCN from 'antd/lib/locale/zh_CN';
import { IntlProvider } from 'react-intl'
import zh_CNJS from './Locale/zh_CN' // import defined messages in Chinese
import en_USJS from './Locale/en_US'
import { ConfigProvider } from 'antd'
import {
  Nav30DataSource,
  Footer11DataSource,
} from './Home/data.source.js';
import Pubsub from 'pubsub-js'

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      Lang: {
        zh_CN: zh_CNJS,
        en_US: en_USJS
      },
      language: 'en_US'
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    Pubsub.subscribe('language', async (res,lang) => {
      this.setState({ language: lang })
    })
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  

  render() {
    return (
      <div style={{overflowX:'hidden'}}>
      <Router>
      <IntlProvider locale={en_USJS} messages={this.state.Lang[this.state.language] || en_USJS}>
        <Header id="Nav3_0"
          key="Nav3_0" dataSource={Nav30DataSource} isMobile={this.state.isMobile}  />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/philosophy" element={<Philosophy />} />
          {/* <Route exact path="/team" element={<Team />} /> */}
          <Route exact path="/knowledgebank" element={<KnowledgeBank />} />
          <Route path="*" element={<Navigate to ="/home" />}/>
        </Routes>
        <Footer dataSource={Footer11DataSource} isMobile={this.state.isMobile} />
      </IntlProvider>
     </Router>
     </div>
    );
  }
}

export default App;

