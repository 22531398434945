/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner5 from './Banner5';
import Feature7 from './Feature7';
import Content0 from './Content0';
import Feature0 from './Feature0';
import Teams0 from './Teams0';
import Content5 from './Content5';
import Content12 from './Content12';
import Content1 from './Conten1';

import {
  Banner50DataSource,
  Feature70DataSource,
  Content00DataSource,
  Content001DataSource,
  Feature00DataSource,
  Teams00DataSource,
  Content50DataSource,
  Content120DataSource,
} from './data.source';
import './less/antMotionStyle.less';
import Pubsub from 'pubsub-js'

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      language: localStorage.getItem('language') || 'en_US'
    };
  }
  setDocument(lang) {

    const svgicon = document.getElementById('svgicon')
    console.log(svgicon);

    if (svgicon) {
      let left = ''
      if (this.state.isMobile) {
        left = lang === 'en_US' ? '-5px' : '-22px'
      } else {
        left = lang === 'en_US' ? '-1px' : '-46px'
      }
      svgicon.style.left = left
    }

  }
  componentDidMount() {

    // 适配手机屏幕;
    setTimeout(() => {
      this.setDocument(this.state.language)
    }, 100)
    enquireScreen((b) => {
      console.log(b);
      this.setState({ isMobile: !!b });
    });
    Pubsub.subscribe('language', async (res, lang) => {
      this.setState({ language: lang })
      this.setDocument(lang)
    })
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      // <Nav2
      //   id="Nav2_0"
      //   key="Nav2_0"
      //   dataSource={Nav20DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Banner5
        id="Banner5_0"
        key="Banner5_0"
        dataSource={Banner50DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature7
        id="Feature7_0"
        key="Feature7_0"
        dataSource={Feature70DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content1 id="Content0_1"
        key="Content0_1"
        dataSource={Content001DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content0
        id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature0
        id="Feature0_0"
        key="Feature0_0"
        dataSource={Feature00DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Teams0
      //   id="Teams0_0"
      //   key="Teams0_0"
      //   dataSource={Teams00DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Content5
        id="Content5_0"
        key="Content5_0"
        dataSource={Content50DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Content12
      //   id="Content12_0"
      //   key="Content12_0"
      //   dataSource={Content120DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <Footer1
      //   id="Footer1_1"
      //   key="Footer1_1"
      //   dataSource={Footer11DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    ];
    return (
      <div
        style={{ minHeight: '100vh' }}
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {/* {this.state.show && children} */}
        {children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
